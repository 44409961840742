<template>
    <nav>
      <div class="nav-wrapper">
        <a class="brand-logo truncate center valign-wrapper" style="display: flex">
          <span style="margin-right: 1px">Lions <span class="hide-on-small-and-down">Club </span>
            Emmerich Rees</span>
          <img src="/logo.gif" style="height: 50px" /></a>
      </div>
    </nav>
  <div class="container">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    state() {
      return this.$store.state;
    },
  },
};
</script>

<style lang="scss">

nav {
  background-color: rgb(56,103,214) !important;
}

</style>
