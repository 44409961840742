<template>
  <div class="home card" v-on:keydown.enter.prevent="onEnterKeyDown">
    <div class="card-content">
      <div class="row">
        <div class="col s12">
          <span class="card-title">Sponsoring Adventskalender {{ year }}</span>
        </div>
        <div class="col s12">
          <p>
            Sehr geehrter Sponsor,<br /><br />
            auf dieser Seite haben Sie die Möglichkeit, Ihr Sponsoring online einzutragen.<br /><br />
            Bei Rückfragen erreichen Sie Herrn Hans-Gerd Bleckmann unter 02851-963070 oder via E-Mail
            <a href="mailto:sponsorenerfassung@lions-emmerich-rees.de">sponsorenerfassung@lions-emmerich-rees.de</a>.<br /><br />
            Bitte füllen Sie die erforderlichen Felder aus, damit wir Ihr Sponsoring bestätigen können.
            <br /><br />
            <span class="red-text">*</span> Erforderlich
          </p>
        </div>
        <form class="col s12">
          <div class="row">
            <div class="input-field col s12 m3">
              <input id="kontonummer" type="text" disabled :value="stammdaten.id" />
              <label for="kontonummer" class="active">Sponsor-ID</label>
            </div>
            <div class="input-field col s12 m9">
              <input id="kontoname" type="text" disabled :value="stammdaten.sponsorName" />
              <label for="kontoname" class="active">Sponsorname</label>
            </div>
            <div class="input-field col s12 l6">
              <input id="anrede" type="text" placeholder="Anrede" v-model.trim="stammdaten.anrede" data-index="1" class="validate" required />
              <label for="anrede" class="active">Anrede<span class="red-text">*</span></label>
              <span class="helper-text" data-error="Bitte geben Sie ihre Anrede an"></span>
            </div>
            <div class="input-field col s12 l6">
              <label for="akgrad" class="active">Titel</label>
              <input id="akgrad" data-index="2" placeholder="" type="text" v-model.trim="stammdaten.akgrad" />
            </div>
            <div class="input-field col s12 16">
              <input id="name" type="text" placeholder="Name" v-model.trim="stammdaten.name" data-index="3" class="validate" required autocomplete="family-name" />
              <label for="name" class="active">Name<span class="red-text">*</span></label>
              <span class="helper-text" data-error="Bitte geben Sie ihren Namen an"></span>
            </div>
            <div class="input-field col s12 16">
              <input id="vorname" type="text" placeholder="Vorname" v-model.trim="stammdaten.vorname" data-index="4" class="validate" required autocomplete="given-name" />
              <label for="vorname" class="active">Vorname<span class="red-text">*</span></label>
              <span class="helper-text" data-error="Bitte geben Sie ihren Vornamen an"></span>
            </div>
            <div class="input-field col s12 16">
              <input id="briefanrede" type="text" disabled :value="stammdaten.briefanrede" />
              <label for="briefanrede" class="active">Briefanrede</label>
            </div>
            <div class="input-field col s12 16">
              <input id="strasse" type="text" placeholder="Straße + Hausnummer" v-model.trim="stammdaten.strasse" data-index="6" class="validate" required autocomplete="street-address" />
              <label for="name" class="active">Straße + Hausnummer<span class="red-text">*</span> </label>
              <span class="helper-text" data-error="Bitte geben Sie ihre Straße mit Hausnummer an"></span>
            </div>
            <div class="input-field col s12 m6">
              <input id="plz" type="number" placeholder="Postleitzahl" min="0" v-model.number="stammdaten.plz" class="validate" required autocomplete="postal-code" data-index="7" />
              <label for="plz" class="active">Postleitzahl<span class="red-text">*</span></label>
              <span class="helper-text" data-error="Bitte geben Sie ihr Postleitzahl an"></span>
            </div>
            <div class="input-field col s12 m6">
              <input id="ort" type="text" placeholder="Ort" v-model.trim="stammdaten.ort" data-index="8" class="validate" required autocomplete="address-level2" />
              <label for="ort" class="active">Ort<span class="red-text">*</span></label>
              <span class="helper-text" data-error="Bitte geben Sie ihren Wohnort an"></span>
            </div>
            <div class="input-field col s12 m6">
              <input id="telefon" type="tel" placeholder="Telefon" v-model.trim="stammdaten.telefon" autocomplete="tel" data-index="9" class="validate" required />
              <label for="telefon" class="active">Telefonnummer<span class="red-text">*</span></label>
              <span class="helper-text" data-error="Bitte geben Sie ihre Telefonnummer an"></span>
            </div>
            <div class="input-field col s12 m6">
              <input id="mobil" type="text" placeholder="Mobil" v-model.trim="stammdaten.mobil" data-index="10" />
              <label for="mobil" class="active">Mobil</label>
            </div>
            <div class="input-field col s12">
              <input id="mail" type="email" class="validate" v-model.trim="stammdaten.mail" placeholder="Ihre E-Mail Adresse" data-index="11" required autocomplete="email" />
              <label for="mail" class="active">Email<span class="red-text">*</span></label>
              <span class="helper-text" data-error="Bitte geben Sie ihr Email Addresse an"></span>
            </div>
            <div class="input-field col s12">
              <h6>Waren Sie schon einmal Sponsor - liegt Ihr Logo bereits vor? <span class="red-text">*</span></h6>
              <p>Wir benötigen Ihr Logo hochauflösend (300 dpi), damit dies schön auf dem Kalender gedruckt werden kann.</p>
              <p>
                <label>
                  <input name="logoVorhanden" type="radio" value="Ja" v-model="stammdaten.logoVorhanden" data-index="10" />
                  <span>Ja</span>
                </label>
              </p>
              <p>
                <label>
                  <input name="logoVorhanden" type="radio" value="Nein" v-model="stammdaten.logoVorhanden" />
                  <span>Nein - bitte schicken Sie Ihr Logo hochauflösend an <a href="mailto:sponsorenerfassung@lions-emmerich-rees.de">sponsorenerfassung@lions-emmerich-rees.de</a></span>
                </label>
              </p>
            </div>
            <div class="input-field col s12">
              <h6>
                Datenschutzerklärung gemäß DSGVO für Sponsoren und Spender
                <span class="red-text">*</span>
              </h6>
              <p>
                <label>
                  <input type="checkbox" v-model="acceptsDsgvo" data-index="11" />
                  <span>Hiermit bestätige ich die Verwendung und Speicherung unserer Daten gemäß DSGVO auf unbestimmte Zeit, sowie zukünftig per E-Mail kontaktiert zu werden. </span>
                </label>
              </p>
            </div>
            <div class="col s12 l6 offset-l6">
              <button @click="saveStammdaten" type="button" class="btn blue darken-1" style="width: 100%" :class="{ disabled: !canSaveStammdaten }">
                Speichern
              </button>
            </div>
          </div>
        </form>
      </div>
      <div v-if="loading" class="loading-screen">
        <div class="preloader-wrapper big active">
          <div class="spinner-layer spinner-blue-only">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="gap-patch">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css';
import StammdatenService from '@/services/stammdaten';
import axios from 'axios';

export default {
  name: 'Home',
  data() {
    return {
      loading: true,
      mail: '',
      kontonummer: '10001',
      year: process.env.VUE_APP_YEAR,
      stammdaten: {
        id: '10010',
        kontaktnummer: '',
        anrede: 'Herr',
        titel: '',
        sponsorName: 'Hans-Gerd Bleckmann Informationssysteme',
        name: '',
        vorname: '',
        strasse: '',
        plz: '',
        ort: '',
        telefon: '',
        mobil: '',
        mail: '',
        logoVorhanden: '',
      },
      acceptsDsgvo: false,
    };
  },
  async mounted() {
    M.AutoInit();
    this.$store.dispatch('clear');
    if (this.$route.query.konto) {
      this.kontonummer = this.$route.query.konto;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/data/isNewSponsor?sponsorId=${this.kontonummer}`);

        const { isNew } = response.data;

        if (!isNew) {
          await this.$router.push('/hinweis');
          return;
        }
      } catch (e) {
        console.error(e);
        await this.$router.push('/fehler');
      }

      const daten = await StammdatenService.load(this.kontonummer);

      if (daten) {
        this.stammdaten = daten;
        this.loading = false;
      }
    }

    M.AutoInit();
    M.updateTextFields();
  },
  updated() {
    M.updateTextFields();
  },
  computed: {
    canSaveStammdaten() {
      if (!this.acceptsDsgvo) {
        return false;
      }

      if (this.stammdaten.mail == null) {
        return false;
      }
      if (!this.stammdaten.mail.includes('@')) {
        return false;
      }
      if (this.stammdaten.strasse == null || this.stammdaten.strasse.toString() === '') {
        return false;
      }
      if (this.stammdaten.telefon == null || this.stammdaten.telefon.toString() === '') {
        return false;
      }
      if (this.stammdaten.ort == null || this.stammdaten.ort.toString() === '') {
        return false;
      }
      if (this.stammdaten.plz == null || this.stammdaten.plz.toString() === '') {
        return false;
      }
      if (this.stammdaten.vorname == null || this.stammdaten.vorname.toString() === '') {
        return false;
      }
      if (this.stammdaten.name == null || this.stammdaten.name.toString() === '') {
        return false;
      }
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const property in this.stammdaten) {
        if (property !== 'mobil' && property !== 'akgrad' && this.stammdaten[property] === '') {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    saveStammdaten() {
      this.$store.dispatch('updateKonto', this.stammdaten);

      this.$router.push(`/erfassen?konto=${this.kontonummer}`);
    },
    onEnterKeyDown($event) {
      const nextDataIndex = Number.parseInt($event.target.getAttribute('data-index'), 10);

      if (Number.isNaN(nextDataIndex)) {
        return;
      }

      const nextInput = document.querySelectorAll('[data-index]')[nextDataIndex];

      if (!nextInput) {
        return;
      }

      nextInput.focus();
    },
  },
};
</script>
<style scoped>
.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(236, 231, 231, 0.822);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
</style>
