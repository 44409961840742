import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
});

export default createStore({
  state: {
    stammdaten: undefined,
    gutscheine: [],
    sachspenden: [],
    barspende: 0,
    bestellteKalender: 0,
  },
  mutations: {
    updateKonto(state, stammdaten) {
      state.stammdaten = stammdaten;
    },
    updateGutscheine(state, gutscheine) {
      state.gutscheine = gutscheine;
    },
    updateSachspenden(state, sachspenden) {
      state.sachspenden = sachspenden;
    },
    updateBarspende(state, barspende) {
      state.barspende = barspende;
    },
    updateKalenderBestellung(state, count) {
      state.bestellteKalender = count;
    },
    clear(state) {
      state.stammdaten = undefined;
      state.gutscheine = [];
      state.sachspenden = [];
      state.barspende = 0;
      state.bestellteKalender = 0;
    },
  },
  actions: {
    updateKonto(context, stammdaten) {
      context.commit('updateKonto', stammdaten);
    },
    updateGutscheine(context, gutscheine) {
      context.commit('updateGutscheine', gutscheine);
    },
    updateSachspenden(context, sachspenden) {
      context.commit('updateSachspenden', sachspenden);
    },
    updateBarspende(context, barspende) {
      context.commit('updateBarspende', barspende);
    },
    updateKalenderBestellung(context, count) {
      context.commit('updateKalenderBestellung', count);
    },
    clear(context) {
      context.commit('clear');
    },
  },
  getters: {
    payload(state) {
      return {
        SponsorId: state.stammdaten.id,
        Kontaktnummer: state.stammdaten.kontaktnummer,
        Anrede: state.stammdaten.anrede,
        titel: state.stammdaten.akgrad,
        SponsorName: state.stammdaten.sponsorName,
        name: state.stammdaten.name,
        vorname: state.stammdaten.vorname,
        strasse: state.stammdaten.strasse,
        postleitzahl: state.stammdaten.plz,
        ort: state.stammdaten.ort,
        telefon: state.stammdaten.telefon,
        mobil: state.stammdaten.mobil,
        mail: state.stammdaten.mail,
        logoVorhanden: state.stammdaten.logoVorhanden !== 'Nein',
        dsgvoOk: true,
        warengutschein1: state.gutscheine.length > 0 ? {
          anzahl: state.gutscheine[0].menge,
          wert: state.gutscheine[0].wert,
          AbholortGewinn: state.gutscheine[0].abholort,
          OeffnungszeitenfuerGewinnabholung: state.gutscheine[0].abholzeit,
        } : undefined,
        warengutschein2: state.gutscheine.length > 1 ? {
          anzahl: state.gutscheine[1].menge,
          wert: state.gutscheine[1].wert,
          AbholortGewinn: state.gutscheine[1].abholort,
          OeffnungszeitenfuerGewinnabholung: state.gutscheine[1].abholzeit,
        } : undefined,
        sachspende1: state.sachspenden.length > 0 ? {
          anzahl: state.sachspenden[0].menge,
          wert: state.sachspenden[0].wert,
          bezeichnung: state.sachspenden[0].bezeichnung,
          AbholortGewinn: state.sachspenden[0].abholort,
          OeffnungszeitenfuerGewinnabholung: state.sachspenden[0].abholzeit,
        } : undefined,
        sachspende2: state.sachspenden.length > 1 ? {
          anzahl: state.sachspenden[1].menge,
          wert: state.sachspenden[1].wert,
          bezeichnung: state.sachspenden[1].bezeichnung,
          AbholortGewinn: state.sachspenden[1].abholort,
          OeffnungszeitenfuerGewinnabholung: state.sachspenden[1].abholzeit,
        } : undefined,
        barspende: state.barspende,
        zurKenntnisGenommen: true,
        KeinSponsoringAberKalenderbestellung: false,
        bestellteKalender: state.bestellteKalender,
      };
    },
  },
  modules: {
  },
  plugins: [vuexSession.plugin],
});
