import axios from 'axios';

export default {
  async load(id) {
    try {
      const requests = [
        axios.get(`${process.env.VUE_APP_API_URL}/Sponsorenstamm/getSponsorstammFromWinLine?sponsorenid=${id}`),
        axios.get(`${process.env.VUE_APP_API_URL}/Sponsorenstamm/getDefaultAnsprechpartnerFromWinLine?sponsorenid=${id}`),
      ];
      const [response, responseAnsprechpartner] = await Promise.all(requests);
      const stammdaten = {
        id: '',
        kontaktnummer: '',
        anrede: '',
        akgrad: '',
        sponsorName: '',
        name: '',
        vorname: '',
        briefanrede: '',
        strasse: '',
        plz: '',
        ort: '',
        telefon: '',
        mobil: '',
        mail: '',
        logoVorhanden: '',
      };
      stammdaten.id = response.data.personenkontoVorlage.kontonummer;
      stammdaten.sponsorName = response.data.personenkontoVorlage.kontoname;
      stammdaten.anrede = responseAnsprechpartner.data.ansprechpartnerStammdatenVorlage.kurzanrede;
      stammdaten.akgrad = responseAnsprechpartner.data.ansprechpartnerStammdatenVorlage.titel;
      stammdaten.name = responseAnsprechpartner.data.ansprechpartnerStammdatenVorlage.name;
      stammdaten.vorname = responseAnsprechpartner.data.ansprechpartnerStammdatenVorlage.vorname;
      stammdaten.strasse = responseAnsprechpartner.data.ansprechpartnerStammdatenVorlage.strasse1;
      stammdaten.plz = responseAnsprechpartner.data.ansprechpartnerStammdatenVorlage.plZ1;
      stammdaten.ort = responseAnsprechpartner.data.ansprechpartnerStammdatenVorlage.ort;
      stammdaten.telefon = responseAnsprechpartner.data.ansprechpartnerStammdatenVorlage.telefon1Nummer;
      stammdaten.mobil = responseAnsprechpartner.data.ansprechpartnerStammdatenVorlage.mobiltelefonNummer;
      stammdaten.mail = responseAnsprechpartner.data.ansprechpartnerStammdatenVorlage.eMailadresse;
      stammdaten.briefanrede = responseAnsprechpartner.data.ansprechpartnerStammdatenVorlage.briefanrede;
      stammdaten.kontaktnummer = responseAnsprechpartner.data.ansprechpartnerStammdatenVorlage.kontaktnummer;
      return stammdaten;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
};
