import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/erfassen',
    name: 'Erfassen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "erfassen" */ '../views/Erfassen.vue'),
  },
  {
    path: '/bestellen',
    name: 'Bestellen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bestellen" */ '../views/Bestellen.vue'),
  },
  {
    path: '/end',
    name: 'End',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "end" */ '../views/End.vue'),
  },
  {
    path: '/hinweis',
    name: 'Hinweis',
    component: () => import(/* webpackChunkName: "hinweis" */ '../views/Hinweis.vue'),
  },
  {
    path: '/fehler',
    name: 'Fehler',
    component: () => import(/* webpackChunkName: "fehler" */ '../views/Fehler.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
